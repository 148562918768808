<template>
  <div class="complaints">
    <v-card>
      <v-card-text>
        <div v-if="!totalRows" class="no-complaints">
          <h5>Ainda não há denúncias a serem avaliadas</h5>
        </div>
        <div v-else class="has-complaints">
          <v-row class="mt-4">
            <v-col>
              <h2>Avaliações denunciadas</h2>
            </v-col>
          </v-row>

          <!-- percorre os comentários e exibe -->
          <v-row
            class="mt-2 mb-2"
            v-bind:key="rating.id"
            v-for="rating in complaints"
          >
            <v-col>
              <v-divider></v-divider>
              <v-row no-gutters class="mt-2">
                <v-col
                  ><h3>Cliente: {{ rating.Company.fantasyName }}</h3></v-col
                >
              </v-row>
              <v-row no-gutters class="mt-2">
                <v-col
                  ><h3>
                    Visitante: {{ getVisitorName(rating.Visitor.User) }}
                  </h3></v-col
                >
              </v-row>

              <v-row no-gutters>
                <v-col class="rating-date">{{
                  formatRatingCreatedAt(rating.createdAt)
                }}</v-col>
              </v-row>

              <v-row no-gutters class="my-2">
                <v-col
                  ><h4>{{ rating.title }}</h4></v-col
                >
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-rating
                    hover
                    length="5"
                    size="15"
                    v-model="rating.ratingValue"
                    readonly
                  ></v-rating>
                </v-col>
              </v-row>

              <v-row no-gutters class="my-3" v-if="rating.comment">
                <v-col md="9">
                  <i class="fa fa-quote-left" aria-hidden="true"></i>
                  {{ rating.comment }}
                  <i class="fa fa-quote-right" aria-hidden="true"></i>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="company-answer"
                  md="9"
                  :key="answer.id"
                  v-for="answer in rating.RatingAnswers"
                >
                  <p>
                    <b>Resposta</b>
                    <br />
                    {{ answer.comment }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn small color="error" @click.stop="deleteRating(rating)">
                    <v-icon left>mdi-cancel</v-icon> Excluir
                  </v-btn>
                  <v-btn
                    small
                    class="ml-2"
                    color="success"
                    @click="keepActive(rating)"
                  >
                    <v-icon left>mdi-check</v-icon> Manter
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="globalPageNumber < totalRows / resultsPerPage">
            <v-col class="d-flex justify-center align-center">
              <v-btn small text @click="nextPage()"> Carregar mais </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Carregando...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" persistent max-width="450">
        <v-card>
          <v-card-text>
            <div class="text-center pa-4">
              <v-icon large color="success">mdi-check</v-icon>
            </div>

            <div class="text-center pa-3">
              <h3>Operação realizada com sucesso</h3>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeSuccessDialog">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  name: "Complaints",
  computed: {},
  data() {
    return {
      loading: false,
      successDialog: false,
      resultsPerPage: 10,
      totalRows: 0,
      globalPageNumber: 1,
      complaints: [],
    };
  },
  methods: {
    nextPage() {
      this.globalPageNumber += 1;

      this.loadComplaints(this.globalPageNumber);
    },
    async loadComplaints(page) {
      try {
        let currentPage = page ? page : 1;

        let params = {
          pagination: 1,
          resultsPerPage: this.resultsPerPage,
          page: currentPage,
        };

        const response = await this.$axios.get(`/ratings/complaints`, {
          params,
        });

        const data = { ...response.data };

        this.setComplaints(data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setComplaints(data) {
      const copyOfData = { ...data };
      this.totalRows = copyOfData.count;

      this.complaints = [...this.complaints, ...copyOfData.rows];
    },
    getVisitorName(user) {
      return `${user.firstName} ${user.lastName}`;
    },
    formatRatingCreatedAt(createdAt) {
      return `${this.$moment(createdAt).format("DD/MM/YYYY [ás] HH:mm")}`;
    },
    async keepActive(rating) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente manter esta avaliação?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        //optei por deixar o motivo != null pra ter uma maneira de saber que já foi reportado uma vez
        await this.$axios.put(`/ratings/${rating.id}`, {
          reported: false,
        });

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteRating(rating) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente excluir esta avaliação?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/ratings/${rating.id}`);

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    closeSuccessDialog() {
      this.complaints = [];
      this.loadComplaints();

      this.successDialog = false;
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  mounted() {
    this.loadComplaints();
  },
};
</script>

<style>
.no-complaints {
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  border: 1px solid rgb(224, 116, 116);
  background-color: rgb(245, 190, 190);
}

.additional-information {
  font-size: 0.8rem;
  color: dimgrey;
  padding-top: 0;
}

.rating-date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.75rem;
  padding-top: 8px;
  padding-left: 15px;
}

.company-answer p {
  background-color: rgb(230, 230, 230);
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
}

.company-answer {
  padding-left: 30px;
}

.product-delivery p {
  margin-bottom: 0.4rem;
  font-size: 0.85rem;
  padding-left: 20px;
  color: aliceblue;
  background-color: rgb(31, 31, 161);
  border-radius: 10px;
}

.service-provison p {
  margin-bottom: 0.4rem;
  font-size: 0.85rem;
  padding-left: 20px;
  color: aliceblue;
  background-color: rgb(238, 135, 39);
  border-radius: 10px;
}
</style>
